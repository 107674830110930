/* helpers //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@mixin transition($trans) {
	-webkit-transition: $trans;
	-moz-transition: $trans;
	-o-transition: $trans;
	transition: $trans;
}
// $direction, $wrap, $align, $justify
@mixin flex($direction, $wrap, $align, $justify) {
	/* init flex */
	display: -webkit-flex;
  display: flex;

	/* direction */
	-webkit-flex-direction: $direction; /* Safari 6.1+ */
	flex-direction: $direction;
	/* wrapping */
	flex-wrap: $wrap;
	-webkit-flex-wrap: $wrap;
	/* align */
	align-items: $align;
	-webkit-align-items: $align;
	/* justify */
	justify-content: $justify;
	-webkit-justify-content: $justify;
}

@mixin align-self($align) {
    -webkit-align-self: $align; /* Safari 7.0+ */
    align-self: $align;
}

@mixin CardFramework($width, $height) {
	width: $width;
	height: $height;

	display: block;
	position: relative;
	padding: 0;
	margin: 0;

	overflow: hidden;

	// background-color: blue;
}

@mixin centerPadding($padding) {
	margin: 0px auto;
	padding-top: $padding;
	padding-bottom: $padding;
}

@mixin parallax($attach, $pos) {
	background-position: $pos;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: $attach;
}

/* colours */
$grey: #232323;
$orange: #fba919;
$mainfont: 'Open Sans', sans-serif;

/* end of helpers */

/* framework //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
body {
	background-color: $grey;
}
.card {
	@include CardFramework(100%, auto);
	background-color: white;
	font-family: $mainfont;
	z-index: 2;
}

.card-container {
	max-width: 1000px;
	height: auto;
	//background-color: red;

	margin: 0px auto;
	display: block;
	position: relative;

	box-sizing: border-box;
}

// card colours

.card-grey {
	background-color:  $grey;
}

.card-white {
	background-color:  white;
}

// card backgrounds

.card-house {
	@include CardFramework(100%, auto);

	background-image: url("../Images/houseBack_.jpg");

	@include parallax(scroll, top);
	@include flex(column, wrap, center, center);
}

.card-parallax {
	// comments due to parallax effect
	@include CardFramework(100%, auto);

	//background-image: url("../Images/gamesroom.jpg");

	//@include parallax(scroll, center);
	@include flex(column, wrap, center, center);
}

.card-pool {
	@include CardFramework(100%, auto);

	background-image: url("../Images/back1.jpg");

	@include parallax(scroll, center);
	@include flex(column, wrap, center, center);
}

.break-sec {

	p {
		color: white !important;
		font-size: 42px !important;
		text-transform: uppercase;
	}
}

// edges

@mixin edge($left) {
	margin: 0 !important;
	padding: 0 !important;

	width: 101% !important;
	max-height: 115px;

	position: absolute;
	left: $left;
}

.edge-bottom {
	@include edge(0px);

	bottom: -1px;
}

.edge-top {
	@include edge(0px);

	top: 0;
}

.flip {
	transform: rotate(180deg);
}

.flip-y {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
}

// padding styles

.text {
 	@include centerPadding(60px);

	p {
		font-family: $mainfont;
		color: $grey;
		font-size: 24px;
		font-weight: 300;
		text-align: justify !important;
		padding: 20px;
	}
}

.text-white {
 	@include centerPadding(60px);

	p {
		font-family: $mainfont;
		color: white;
		font-size: 24px;
		font-weight: 300;
		text-align: justify !important;
		padding: 20px;
	}
}

.responsive-padding-100 {
	margin-top: 100px;
	margin-bottom: 50px;
}

// Headings

h1 {
	padding: 10px;
	margin: 0px auto;
	text-align: center;

	img {
		width: 60px;
		height: auto;
		display: inline;
	}

	p {
		position: relative;
		bottom: 12px;
		left: 20px;
		display: inline;
		margin: 0;
		padding: 0;

		font-family: $mainfont;
		font-weight: 300;;
		color: $grey;
		font-size: 50px !important;
	}
}

.heading_2, h3 {
	font-family: $mainfont;
    font-size: 42px;
    font-weight: 300 !important;
    text-align: left;
	 padding-left: 20px;

	 @include transition(.25s ease-in-out);
}

// listpoints

.responsive-list {

		width: 100%;
		height: auto;
		margin: 0px auto;

		@include flex(row, wrap, space-around, center);

		li {
			list-style-type: none;
			font-family: $mainfont;
			font-weight: 300;
			font-size: 22px;
			width: 45%;
			text-align: left;
			display: inline-block;
			padding: 10px;
			color: white;

			@include transition(.25s ease-in-out);
		}

		li i {
			color: green;
			padding-right: 10px;
		}
}

.NormalList {
	ul {
		padding: 20px;
		text-align: left;
		font-size: 20px;
		display: block;
		margin-left: 20px;

		text-indent: 2px;
		list-style-position: outside;
	}

	li {
		margin-top: 10px;
		font-weight: 300;
	}
}

/* End of Framework */

/* Map ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

.mapDiv, .map {
	height: 450px;
	width: 95%;
	border: 0;
	display: block;
	margin: 0px auto;
}

.scrolloff {
	pointer-events: none;
}


/* Menu //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

.up-arrow {
	position: fixed;
	bottom: 50px;
	right: 50px;
	z-index: 1000000000;
	width: 48px;
	height: 48px;
	display: none;

	&:hover {
		cursor: pointer;
	}
}

.menu {

	margin: 130px auto 130px auto;

	img {
		width: 90%;
		max-width: 750px;
		height: auto;
		display: block;

		@include centerPadding(40px);
	}

}

.Navigation {
	width: auto;
	font-weight: 200;
	margin: 0px auto;

	@include transition(.25s ease-in-out);
}

.Navigation {

	ul {
		margin: 0px auto;
		text-align: center;
	}

	li {
		list-style-type: none;
		font-family: $mainfont;
		display: inline-block;
		font-size: 24px;
		text-align: center;
		width: 150px;
		height: 30px;
		padding: 10px;

		@include transition(.25s ease-in-out);
	}

	li:hover{
		background-color: #fba919;

		@include transition(.25s ease-in-out);
	}

}

/* End of Menu */

/* Navigation //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

$menuWidth: 250px;

.PhoneNav-Menu {
	position: fixed;
	right: 0;
	top: 0;

	height: 100%;
	width: 0;

	background-color: $grey;
	@include transition(.5s ease-in-out);

	ul {
	  font-family: $mainfont;
	  font-weight: 400;
	  font-size: 18px;
	  color: white;

	  position: relative;
	  top: 70px;
	  left: 20px;

	  list-style-type: none;

	  @include transition(.5s ease-in-out);
	}

	li a, i {
	  color: white;
	  text-decoration: none;
	  text-transform: uppercase;

	  @include transition(.25s ease-in-out);
	}

	li:hover a, li:hover i {
	  color: $orange;

	  @include transition(.25s ease-in-out);
	}

	li {
	  margin-bottom: 30px;
	  color: white;
	  width: 250px;
	  overflow: hidden;
	  @include transition(.25s ease-in-out);
	}
}

.PhoneNav-Menu.open {
	width: $menuWidth;

	@include transition(.5s ease-in-out);
}

.PhoneNav {
	position: fixed;
	top: 0;
	right: 0;

	height: 50px;
	width: 150px;

	background-color: rgba($grey, 0.5);
	text-align: center;

	z-index: 101;
	display: none;
	overflow: hidden;

	p {
		font-family: $mainfont;
		font-size: 16px;
		font-weight: 300;
		color: white;

		position: absolute;
		right: 80px;
		bottom: 20px;

		@include transition(.5s ease-in-out);
	}

}

.menuburger {
	width: 35px;
	height: 30px;

	position: absolute;
	right: 20px;
	bottom: 12px;

	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);

 	@include transition(.5s ease-in-out);
 	cursor: pointer;

	span {
		display: block;
		position: absolute;
		height: 6px;
		width: 100%;
		background: $orange;
		border-radius: 9px;
		opacity: 1;
		left: 0;

		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);

		@include transition(.25s ease-in-out);
	}

	span:nth-child(1) {
		top: 0px;
		-webkit-transform-origin: left center;
		-moz-transform-origin: left center;
		-o-transform-origin: left center;
		transform-origin: left center;
	}

	span:nth-child(2) {
		top: 12px;
		-webkit-transform-origin: left center;
		-moz-transform-origin: left center;
		-o-transform-origin: left center;
		transform-origin: left center;
	}

	span:nth-child(3) {
	  top: 24px;
		-webkit-transform-origin: left center;
		-moz-transform-origin: left center;
		-o-transform-origin: left center;
		transform-origin: left center;
	}
}

.menuburger.open {

	//right: 250px;

	span:nth-child(1) {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
		left: 5px;
	}

	span:nth-child(2) {
		width: 0%;
		opacity: 0;
	}

	span:nth-child(3) {
		left: 5px;

		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
}

body {
	position: relative;
	right: 0;
	@include transition(0.5s ease-in-out);
}

body.open {
	right: $menuWidth;
	@include transition(0.5s ease-in-out);
}

/* End of Navigation */

/* location page ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

.Amenities {
	width: 100%;
	height: auto;
	background: url(Images/Mall.jpg) no-repeat center fixed;
	background-attachment: fixed;
	text-align: center;
	margin: 0;
	padding: 0;
	border: 0;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.AttractionContent {
	width: 100%;
	height: auto;
	margin: 0px auto;
	// $direction, $wrap, $align, $justify
	@include flex(row, wrap, center, center);

 	img {
		width: 200px;
		height: auto;
		margin: 20px;

		transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-webkit-transition: .25s ease-in-out;
	}

	img:hover {
		filter: brightness(120%);
		-webkit-filter: brightness(120%);
		-ms-filter: brightness(120%);

		@include transition(.25 ease-in-out);
	}
}

/* GALLERY ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

.gallery {
	// $direction, $wrap, $align, $justify
	@include flex(column, wrap, center, center);

	margin: 0px auto;
	width: 100%;
	position: relative;
	display: block;

	img {
		opacity: 1;

		width: calc(33% - 40px);
		height: auto;
		margin: 20px;
		padding: 0px;
		background-color: white;

		@include transition(.25s ease-in-out);
	}
}

.gallery:hover img {
	opacity: 0.5 !important;

	@include transition(.25s ease-in-out);
}

.gallery img:hover {
	opacity: 1 !important;

	@include transition(.25s ease-in-out);
}

.gallery_normal {
	width: 100%;
	overflow: hidden;
}

.gallery_normal img{
	width: calc(50% - 20px);
	display: inline-block;
	margin: 5px;
}


/* MAP ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*

* MAP */

.zoomPic {
	position: absolute;
	opacity: 0.75;

	@include transition(.25s easy-in-out);
}

.zoomPic:hover {
	opacity: 1;

	@include transition(.25s easy-in-out);
	cursor: pointer;
}

.FloorPlanContainer {
	position: relative;
	margin: 0px auto;
	width: 498px;
	height: 444px;
	background-image: url(../Images/HousePlan.png);
	background-size: cover;
}

.imageOnHover {
	width: 400px;
	height: auto;
	z-index: 10;
	position: absolute;
	border: 7px solid white;
	display: none;

	box-shadow: 0px 0px 15px #888888;
}

/* RENTAL RATES *////////////////////////////////////////////////////////////////////////////////////////////////*/

.get_rates {
	display: none;
}

/*	Tables	*/
.StandardTable {
	margin: 0 auto;
	font-size: 18px;
	width: calc(100% - 50px);
}

.StandardTable table, .StandardTable th, .StandardTable td{
	text-align: left;
	border: 1px solid #dddddd;
}

.StandardTable {

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	td {
		padding-right: 30px;
		padding-left: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	tr:hover {
		background-color: #9fd5a1;
	}
}

.firstRow {
	color: white;
	background-color: #4caf50 !important;
}

/*	Tables	*/

/* Form */

.MainForm {
	font-size: 24px;
	font-weight: 300;
	text-align: left;
	-webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;

	 @include transition(.25s ease-in-out);
}

.input {
	-moz-box-shadow: 0;
	box-shadow: 0;
	-webkit-box-shadow: 0;
	outline: none;
}

.RateCalc {
	font-size: 24px;
	font-weight: 300;
	text-align: left;
	position: relative;
	left: 25px;

	-webkit-column-count: 2; /* Chrome, Safari, Opera */
	-moz-column-count: 2; /* Firefox */
	column-count: 2;

	 @include transition(.25s ease-in-out);
}


.RateCalc input, .RateCalc textarea, .RateCalc select{
	width: calc(100% - 50px);
	margin-top: 10px;
	font-size: 20px;
	font-weight: 300;
	font-family: $mainfont;
	border: 1px solid #4c4b4b;

	@include transition(.25s ease-in-out);
}

.MainForm input, .MainForm textarea, .MainForm select{
	width: calc(100% - 50px);
	margin-top: 10px;
	font-size: 20px;
	font-weight: 300;
	font-family: $mainfont;
	border: 1px solid #4c4b4b;

	@include transition(.25s ease-in-out);
}

.FormError {
	border: 1px solid red !important;
}

.MainForm input:focus, .MainForm textarea:focus{
	border: 1px solid #fba919;

	-moz-box-shadow: 0;
	box-shadow: 0;
	-webkit-box-shadow: 0;

	@include transition(.25s ease-in-out);
}
/* Form */

.Receipt {
	margin: 0 auto;
	font-size: 18px;
	width: calc(100% - 50px);
}

.Receipt table, .Receipt th, .Receipt td{
	border: 1px solid #dddddd;
	text-align: right;
}

.Receipt td {
	padding-right: 30px;
	padding-left: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.Receipt tr:hover {
	background-color: #9fd5a1;
}

.firstRowReceipt {
	background-color: #f2f2f2 !important;
}

/*	Tables	*/

.extra1, .extra2, .extra3 {
	display: none;
}

/* Contact Form /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

.HomeWidget {
	height: 100px;
	width: auto;
	margin: 0px auto;
	position: relative;

	@include transition(.25 ease-in-out);
}

.HomeWidget:hover {
	height: 100px;
	width: auto;

	filter: brightness(110%);
	-webkit-filter: brightness(110%);
	-ms-filter: brightness(110%);

	@include transition(.25 ease-in-out);
}

.MainForm {
	font-size: 24px;
	font-weight: 300;
	text-align: left;
	margin: 0px auto;

	-webkit-column-count: 2; /* Chrome, Safari, Opera */
	-moz-column-count: 2; /* Firefox */
	column-count: 2;

	@include transition(.25 ease-in-out);
}

.errorField {
	border: 1px solid red !important;
}

.correctField {
	border: 0;
}

/* footer //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

.footer {
	color: grey;
	text-align: center;
	font-family: $mainfont;
}

.footer {

	li{
		list-style-type: none;
		display: inline-block;
		color: white;
		margin-right: 20px;
		margin-top: 10px;

		@include transition(.25s ease-in-out);
	}

	a:hover {
		color: grey;

		@include transition(.25s ease-in-out);
	}

	ul {
		margin: 0px auto;
	}

	img {
		width: 195px;
		height: auto;
		margin: 20px;
	}

}

/* end of footer */

/* phone mode //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@media ( max-width : 920px ) {

	.imageOnHover {
		width: 300px;
		height: auto;
		z-index: 100000;
		position: absolute;
		border: 7px solid white;
		display: none;

		box-shadow: 0px 0px 10px #888888;
	}

}

@media ( max-width : 820px ) {

	.zoomPic {
		display: none;
	}

	.FloorPlanContainer {
		position: relative;
		margin: 0px auto;
		width: 349px;
		height: 311px;
		background-image: url(../Images/HousePlan.png);
		background-size: cover;
	}

	.gallery {
		-webkit-flex-direction: row;
		flex-direction: row;
		height: auto;

		img {
			margin: 5px;
		}

	}

	.MainForm, .RateCalc{
		-webkit-column-count: 1 !important; /* Chrome, Safari, Opera */
		-moz-column-count: 1 !important; /* Firefox */
		column-count: 1 !important;

		@include transition(.25s ease-in-out);
	}

	.Receipt {
		td {
			text-align: left;
			width: 50%;
		}
	}

}

@media ( max-width : 570px ) {

	.text p {
		font-size: 20px;
	}

	.Navigation {
		display: none;
	}

	.footer-menu {
		display: none;
	}

	.menu {
		@include centerPadding(20px);
	}

	body {
		/*position: relative;
		top: 50px;*/
	}

	.PhoneNav {
		display: block;
	}

	h1 {
		img {
			width: 50px;
		}

		p {
			font-size: 28px !important;
			left: 0px;
		}
	}

	.responsive-padding-100 {
		margin-top: 50px;
	}

	.responsive-list {
		li {
			width: 80%;
		}
		span {
			float: right;
			text-align: right !important;
		}
	}

	.HomeWidget {
		height: 80px;
		width: auto;
		margin: 0px auto;
		position: relative;

		@include transition(.25 ease-in-out);
	}

}

/* MAINTENANCE ///////////////////////////////////////////////////////////////////////////////////*/

.maintenance {
	width: 100%;
	height: 100%;
	display: block;
	position: fixed;
	left: 0;
	top: 0;

	background-image: url("../images/houseBack_.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@include flex(column, wrap, center, center);

	p {
		font-family: $mainfont;
		font-weight: 300;
		font-size: 32px;
		color: white;
	}
}
